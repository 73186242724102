import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendEmailVerification
} from 'firebase/auth';
import { appEnv } from '../../../lib';

const firebaseConfigProd = {
  apiKey: 'AIzaSyAh5bLyj21c8jnHNQ-XtpzSvOAvRyAbE30',
  authDomain: 'user-authentication-60afe.firebaseapp.com',
  projectId: 'user-authentication-60afe',
  storageBucket: 'user-authentication-60afe.appspot.com',
  messagingSenderId: '886914562736',
  appId: '1:886914562736:web:b2a755c5bb681c06505975',
  measurementId: 'G-2KXNSNT6T3'
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyArda-ZXPZ5IAGAjTnD1PheCIQy4UeGCkg',
  authDomain: 'grapesappdev.firebaseapp.com',
  projectId: 'grapesappdev',
  storageBucket: 'grapesappdev.appspot.com',
  messagingSenderId: '1013020606050',
  appId: '1:1013020606050:ios:1d425b38e44484d15a5b3b',
  measurementId: 'G-2KXNSNT6T3'
};

const env = (): string => {
  const serverData = (window as any).SERVER_DATA;
  if (!serverData) {
    return 'test';
  }
  return serverData.ENV;
};

const firebaseConfig = ['local', 'development', 'staging', 'test'].includes(env())
  ? firebaseConfigDev
  : firebaseConfigProd;

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

export const getFirebaseAuth = () => {
  return auth;
};
export const firebaseSignIn = (email: string, password: string): Promise<string> => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential: any) => {
      const user = userCredential.user;
      return user.getIdToken(false);
    })
    .catch((error: any) => {
      console.error(error.code);
      console.error(error.message);
      throw error;
    });
};

export const firebaseSignInWithGoogle = (): Promise<string> => {
  return signInWithPopup(auth, googleProvider)
    .then((userCredentials) => {
      const user = userCredentials.user;
      return user.getIdToken(false);
    })
    .catch((error) => {
      console.error(error.code);
      console.error(error.message);
      throw error;
    });
};

export const firebaseSignUp = (email: string, password: string): Promise<void> => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredentials) => {
      return sendEmailVerification(userCredentials.user);
    })
    .then(() => {
      console.log('Verification email send');
    })
    .catch((error) => {
      console.error(error.code);
      console.error(error.message);
      throw error;
    });
};
