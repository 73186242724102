import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>((itemProps, ref) => (
  <RouterLink ref={ref} {...itemProps} role={undefined} />
));

interface MainMenuItemProps {
  onClick: VoidFunction;
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

export const MainMenuItem = ({ icon, primary, to, onClick }: MainMenuItemProps) => {
  return (
    <li>
      <ListItem onClick={onClick} button component={Link} to={to}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};
