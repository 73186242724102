import { PaymentIntent } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { stripeClient } from '../index';

export const usePaymentIntent = (clientSecret: string): [boolean, PaymentIntent | undefined] => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>();

  useEffect(() => {
    setIsLoading(true);
    stripeClient.then((stripe) =>
      stripe
        ?.retrievePaymentIntent(clientSecret)
        .then((result) => setPaymentIntent(result.paymentIntent))
        .then(() => setIsLoading(false))
    );
  }, [clientSecret]);

  return [isLoading, paymentIntent];
};
