import { useSnackbar } from 'notistack';
import { ReactNode, useContext, createContext, useState, useEffect, useCallback } from 'react';
import { RoutesDefinition } from 'routes';
import { firebaseSignIn, firebaseSignInWithGoogle, getFirebaseAuth, firebaseSignUp } from '../api';

interface SessionContextStateShape {
  isLogged: boolean;
  token: string | null;
  isEmailVerified: boolean;
  isReady: boolean;
}

interface SessionContextShape extends SessionContextStateShape {
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  loginWithGoogle: () => Promise<void>;
  logout: VoidFunction;
  logoutAndStayWhereYouAre: VoidFunction;
  signUpWithEmailAndPassword: (email: string, password: string) => Promise<void>;
}

interface SessionContextProviderProps {
  children: ReactNode;
}

export const SessionContext = createContext<SessionContextShape | undefined>(undefined);

export const SessionContextProvider = ({ children }: SessionContextProviderProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [contextState, setContextState] = useState<SessionContextStateShape>({
    isLogged: false,
    token: null,
    isEmailVerified: false,
    isReady: false
  });

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    await firebaseSignIn(email, password);
  };

  const loginWithGoogle = async () => {
    await firebaseSignInWithGoogle();
  };

  const logout = () => {
    getFirebaseAuth()
      .signOut()
      .then(() => window.location.replace(`${window.location.origin}${RoutesDefinition.login}`));
  };

  const checkIfAdmin = useCallback(
    (email: string) => {
      const isAdmin = email.toLowerCase().endsWith('@grapes.com') || email.toLowerCase().endsWith('@droidsonroids.pl');

      if (isAdmin) {
        return true;
      }

      enqueueSnackbar('Account with admin privileges must be used.', { variant: 'error' });
      getFirebaseAuth().signOut();
      throw new Error('You are not admin');
    },
    [enqueueSnackbar]
  );

  const logoutAndStayWhereYouAre = () => {
    getFirebaseAuth().signOut();
  };

  const signUpWithEmailAndPassword = async (email: string, password: string) => {
    await firebaseSignUp(email, password);
  };

  useEffect(() => {
    return getFirebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        checkIfAdmin(user.email || '');
        user.getIdToken(true).then((latestToken) =>
          setContextState({
            token: latestToken,
            isLogged: true,
            isEmailVerified: user.emailVerified,
            isReady: true
          })
        );
      } else {
        setContextState({
          token: null,
          isLogged: false,
          isEmailVerified: false,
          isReady: true
        });
      }
    });
  }, [checkIfAdmin]);

  return (
    <SessionContext.Provider
      value={{
        loginWithEmailAndPassword,
        loginWithGoogle,
        logout,
        logoutAndStayWhereYouAre,
        signUpWithEmailAndPassword,
        ...contextState
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a SessionContextProvider');
  }
  return context;
};
