import ky, { NormalizedOptions, Options } from 'ky';
import { useMemo } from 'react';
import { API_PREFIX } from 'common';
import { useSession } from 'features/auth';

const unauthorizedHandler = (
  request: Request,
  options: NormalizedOptions,
  response: Response,
  logout: VoidFunction
) => {
  if (response.status === 401) {
    logout();
  }
};

interface ClientOptions {
  timeout?: number;
}

const apiClient = (token: string | null, logoutHandler: VoidFunction, options: ClientOptions) => {
  const config: Options = {
    prefixUrl: API_PREFIX as string,
    hooks: {
      afterResponse: [(request, o, response) => unauthorizedHandler(request, o, response, logoutHandler)]
    },
    ...options
  };

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`
    };
  }

  return ky.create(config);
};

export const useClient = (options: ClientOptions = { timeout: 10000 }) => {
  const { token, logout } = useSession();

  return useMemo(() => {
    return apiClient(token, logout, options);
  }, [token, logout, options]);
};
