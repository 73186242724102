import { styled } from '@mui/system';
import { useCancelAlarmMutation } from './useCancelAlarmMutation';
import { useTurnOnAlarmMutation } from './useTurnOnAlarmMutation';

const AlarmOn = styled('div')({
  cursor: 'pointer',
  width: '32px',
  height: '32px',
  borderRadius: '16px',
  border: '4px solid lightgray',
  backgroundColor: 'gray',
  animation: 'blink-animation 0.5s steps(20, start) infinite',
  '-webkit-animation': 'blink-animation 0.5s steps(20, start) infinite',
  '@keyframes blink-animation': {
    '0%': {
      backgroundColor: 'lightgray'
    },
    '50%': {
      backgroundColor: 'red'
    },
    '100%': {
      backgroundColor: 'lightgray'
    }
  },
  '@-webkit-keyframes blink-animation': {
    '0%': {
      backgroundColor: 'lightgray'
    },
    '50%': {
      backgroundColor: 'red'
    },
    '100%': {
      backgroundColor: 'lightgray'
    }
  }
});

const AlarmOff = styled('div')({
  cursor: 'pointer',
  width: '32px',
  height: '32px',
  borderRadius: '16px',
  border: '4px solid darkgray',
  backgroundColor: 'gray'
});

type Props = {
  orderId: string;
  on: boolean;
  onChange: VoidFunction;
};

export const OrderAlarm = ({ orderId, on, onChange }: Props) => {
  const cancelAlarm = useCancelAlarmMutation();
  const turnOnAlarm = useTurnOnAlarmMutation();

  const onClickAlarmOn = async (): Promise<void> => {
    if (!confirm('Do you want to turn OFF alarm?')) {
      return;
    }
    await cancelAlarm.mutateAsync({ id: orderId });
    onChange();
  };

  const onClickAlarmOff = async (): Promise<void> => {
    if (!confirm('Do you want to turn ON alarm?')) {
      return;
    }
    await turnOnAlarm.mutateAsync({ id: orderId });
    onChange();
  };

  if (on) {
    return <AlarmOn onClick={onClickAlarmOn} />;
  }
  return <AlarmOff onClick={onClickAlarmOff} />;
};
export default OrderAlarm;
