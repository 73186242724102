import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const TitleTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'right',
  backgroundColor: '#f4f4f4',
  width: '200px'
}));

interface Address {
  companyName: string;
  name: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  cityLocality: string;
  stateProvince: string;
  postalCode: string;
  countryCode: string;
  addressResidentialIndicator: string;
}

type Props = {
  address: Address;
};

export const AddressTable = ({ address }: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TitleTableCell>Company name</TitleTableCell>
            <TableCell>{address.companyName}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>Name</TitleTableCell>
            <TableCell>{address.name}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>Phone</TitleTableCell>
            <TableCell>{address.phone}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>Address line 1</TitleTableCell>
            <TableCell>{address.addressLine1}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>Address line 2</TitleTableCell>
            <TableCell>{address.addressLine2}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>City</TitleTableCell>
            <TableCell>{address.cityLocality}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>State</TitleTableCell>
            <TableCell>{address.stateProvince}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>Postal code</TitleTableCell>
            <TableCell>{address.postalCode}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>Country code</TitleTableCell>
            <TableCell>{address.countryCode}</TableCell>
          </TableRow>
          <TableRow>
            <TitleTableCell>Address residential indicator</TitleTableCell>
            <TableCell>{address.addressResidentialIndicator}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default AddressTable;
