import { z } from 'zod';
import { i18n } from './i18n';

export const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_small) {
    return { message: i18n.t('validation.generic.minValue', { min: issue.minimum }) };
  }

  if (issue.code === z.ZodIssueCode.invalid_string) {
    if (issue.validation === 'email') {
      return { message: i18n.t('validation.email') };
    }
  }

  return { message: ctx.defaultError };
};
