import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { useSession } from 'features/auth';
import { MainMenu } from './MainMenu';

export const AppLayout: React.FC = ({ children }) => {
  const { logout } = useSession();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const openMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <Box sx={{ backgroundColor: '#f4f4f4', width: '100%', height: '100%', minHeight: '100vh' }}>
      <Drawer open={isMenuOpen} onClose={closeMenu}>
        <MainMenu onClick={closeMenu} />
      </Drawer>
      <AppBar position="static" sx={{ mb: 4 }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={openMenu}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Grapes admin panel
          </Typography>
          <Button color="inherit" onClick={logout}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
      {children}
    </Box>
  );
};
