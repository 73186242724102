import { Button } from '@mui/material';
import { PropsWithChildren } from 'react';

type FieldTypes = number | string | boolean;

interface Props<T extends Record<string, FieldTypes> = Record<string, FieldTypes>> {
  data: T[];
  fields: { key: keyof T; header: string }[];
  fileName: string;
}

const DELIMITER = ',';

const parseField = (field: FieldTypes) => {
  if (typeof field === 'number' || typeof field === 'boolean') {
    return field;
  }

  return [DELIMITER, '\n', '\r'].some((char) => field.includes(char)) ? `"${field}"` : field;
};

export const DownloadCsv = ({ data, fields, fileName, children }: Required<PropsWithChildren<Props>>) => {
  const header = fields.map((f) => f.header).join(DELIMITER);
  const rows = data.map((row) => fields.map(({ key }) => parseField(row[key])).join(DELIMITER));

  const blob = new Blob([[header, ...rows].join('\n')], { type: 'text/csv;charset=utf-8,' });

  const objUrl = URL.createObjectURL(blob);
  return (
    <Button href={objUrl} download={fileName}>
      {children}
    </Button>
  );
};
