import * as Sentry from '@sentry/react';
import { appEnv } from './AppEnv';

const SENTRY_DSN = String((window as any)?.SERVER_DATA?.REACT_APP_SENTRY_DSN);
const SENTRY_RELEASE = String((window as any)?.SERVER_DATA?.REACT_APP_SENTRY_RELEASE);

if (!appEnv.isLocal() && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: appEnv.getEnv(),
    release: SENTRY_RELEASE ? SENTRY_RELEASE : 'unknown',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.05
  });
}
