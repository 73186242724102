import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider as LibQueryClientProvider } from 'react-query';

interface ErrorResponse {
  response: {
    status: number;
  };
}

const hasResponseStatus = (error: unknown): error is ErrorResponse => {
  return (
    typeof error === 'object' &&
    !!error &&
    'response' in error &&
    typeof (error as ErrorResponse).response === 'object' &&
    !!(error as ErrorResponse).response &&
    'status' in (error as ErrorResponse).response
  );
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (error: unknown) => {
        console.error('API ERROR');
        if (hasResponseStatus(error)) {
          if (error.response.status === 404) {
            window.location.href = '/not-found';
          }
        }
      }
    }
  }
});

interface QueryClientProviderProps {
  children: ReactNode;
}

export const QueryClientProvider = ({ children }: QueryClientProviderProps) => {
  return <LibQueryClientProvider client={queryClient}>{children}</LibQueryClientProvider>;
};
