import Button from '@mui/material/Button';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FC, FormEvent } from 'react';

const Form: FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href
      }
    });

    if (result.error) {
      console.log(result.error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button onClick={handleSubmit}>Submit</Button>
    </form>
  );
};
export default Form;
