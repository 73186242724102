import { Button, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { ConfirmDialog } from './ConfirmDialog';

interface ConfirmActionButtonProps {
  onConfirm: VoidFunction;
  name: string;
  confirmationMessage: string;
  isLoading: boolean;
  variant?: 'outlined' | 'contained';
  color?: 'error' | 'success';
  disabled?: boolean;
}

export const ConfirmActionButton = ({
  onConfirm,
  confirmationMessage,
  name,
  variant = 'contained',
  color = 'success',
  disabled = false,
  isLoading
}: ConfirmActionButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };
  return isLoading ? (
    <CircularProgress size="1rem" />
  ) : (
    <>
      <Button color={color} variant={variant} onClick={() => setOpen(true)} disabled={disabled}>
        {name}
      </Button>
      <ConfirmDialog
        open={open}
        onConfirm={handleConfirm}
        onCancel={() => setOpen(false)}
        content={confirmationMessage}
      />
    </>
  );
};
