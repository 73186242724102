import { HTTPError } from 'ky';

export const handleBackendError = async (err: unknown): Promise<string> => {
  if (err instanceof HTTPError) {
    return err.response?.json().then((data) => {
      const details = data.detail ?? 'unkown error';
      return Array.isArray(details) ? details.join(',\n') : details;
    });
  }
  return 'unknown error';
};
