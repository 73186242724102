import { useQuery } from 'react-query';
import { z } from 'zod';
import { useClient } from 'lib';

const responseSchema = z.object({
  userId: z.string(),
  personalData: z.object({
    name: z.string().optional(),
    phone: z.string().optional(),
    email: z.string().optional(),
    birthday: z.string().optional()
  }),
  isDeleted: z.boolean(),
  deletedAt: z.string().optional(),
  stripeCustomerId: z.string().optional()
});
export type UserDetailsQueryResponse = z.infer<typeof responseSchema>;

export const useUserDetailsQuery = (id: string) => {
  const client = useClient();

  return useQuery({
    queryKey: ['user-details', id],
    queryFn: () =>
      client
        .get(`customer-profiles/${id}`, { headers: { 'X-Grapes-Api-Version': '2' } })
        .then((res) => res.json())
        .then((res) => responseSchema.parse(res))
  });
};
