import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { CSSProperties } from 'react';
import { useController } from 'react-hook-form';

type Option = {
  value: string;
  label: string;
};

interface Props {
  name: string;
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  options: Option[];
  style?: CSSProperties;
}

export const SelectInput = ({ name, label, defaultValue, disabled, options, style, ...rest }: Props): JSX.Element => {
  const {
    field: { ref, ...fieldProps },
    fieldState: { error }
  } = useController({
    name,
    defaultValue
  });

  const hasError = Boolean(error);
  const errorMessage: string = error ? String(error.message) : '';

  return (
    <TextField
      error={hasError}
      id="outlined-basic"
      select
      label={String(label) === 'undefined' ? name : label}
      variant="outlined"
      helperText={errorMessage}
      disabled={disabled}
      ref={ref}
      {...rest}
      {...fieldProps}
      style={style}
    >
      {options.map((option: Option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
