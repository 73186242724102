import { styled } from '@mui/system';

const Orange = styled('div')({
  backgroundColor: '#ffb74d',
  fontColor: 'black',
  borderRadius: '15px',
  textAlign: 'center',
  width: '80px',
  lineHeight: '30px',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const Rose = styled('div')(() => ({
  backgroundColor: '#f48fb1',
  color: 'black',
  borderRadius: '15px',
  textAlign: 'center',
  width: '80px',
  lineHeight: '30px',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}));

const Red = styled('div')(() => ({
  backgroundColor: '#ef5350',
  color: '#eeeeee',
  borderRadius: '15px',
  textAlign: 'center',
  width: '80px',
  lineHeight: '30px',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}));

const White = styled('div')(() => ({
  backgroundColor: '#eeeeee',
  color: 'black',
  borderRadius: '15px',
  textAlign: 'center',
  width: '80px',
  lineHeight: '30px',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}));

const Unspecified = styled('div')(() => ({
  backgroundColor: '#404040',
  color: 'white',
  borderRadius: '15px',
  textAlign: 'center',
  width: '80px',
  lineHeight: '30px',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}));

type Props = {
  color: string;
};

export const WineColor = ({ color }: Props) => {
  if (color === 'rose') {
    return <Rose>rose</Rose>;
  }
  if (color === 'orange') {
    return <Orange>orange</Orange>;
  }
  if (color === 'red') {
    return <Red>red</Red>;
  }
  if (color === 'white') {
    return <White>white</White>;
  }
  return <Unspecified>{color}</Unspecified>;
};
