import { Container } from '@mui/material';
import { ReactNode } from 'react';
import { PageLayoutLoader } from './PageLayoutLoader';

interface PageLayoutProps {
  children: ReactNode;
  header?: ReactNode;
  isLoading?: boolean;
  maxWidth?: 'lg' | 'xl';
}

export const PageLayout = ({ children, header, isLoading = false, maxWidth = 'lg' }: PageLayoutProps) => {
  return (
    <Container
      component="main"
      maxWidth={maxWidth}
      sx={{ mb: 4 }}
      style={{
        marginBottom: 0,
        paddingBottom: 32
      }}
    >
      {header}
      {isLoading ? <PageLayoutLoader /> : children}
    </Container>
  );
};
