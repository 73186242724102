import { useMutation } from 'react-query';
import { useClient } from 'lib';

export const useTurnOnAlarmMutation = () => {
  const client = useClient();

  return useMutation({
    mutationKey: 'turn-on-order-alarm',
    mutationFn: (data: { id: string }) => client.post(`orders/${data.id}/alarms`)
  });
};
