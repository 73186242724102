import { zodResolver } from '@hookform/resolvers/zod';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormInput } from 'common';
import { useSession } from 'features/auth';

const formSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(1)
    .nonempty()
    .transform((val) => val.trim())
});

export type SignUpFormData = z.infer<typeof formSchema>;

export const SignUpForm = () => {
  const { signUpWithEmailAndPassword } = useSession();

  const [isLoading, setIsLoading] = useState(false);
  const [isGlobalError, setGlobalError] = useState(false);

  const formMethods = useForm<SignUpFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {
    formMethods.watch(() => setGlobalError(false));
  }, [formMethods]);

  const handleSubmit = async ({ email, password }: SignUpFormData): Promise<void> => {
    setIsLoading(true);
    try {
      await signUpWithEmailAndPassword(email, password);
    } catch (err: any) {
      if (err.code === 'auth/email-already-in-use') {
        formMethods.setError('email', {
          type: 'email-already-in-use',
          message: 'Emails is already in use'
        });
      } else {
        setGlobalError(true);
        console.error(err);
      }
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Stack spacing={2}>
          {isGlobalError ? <Alert severity="error">Can not sign up!</Alert> : ''}
          <FormInput name="email" placeholder="Email" />
          <FormInput name="password" placeholder="Password" type="password" />
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Sign up'}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
