import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface PageLayoutHeaderProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  withBackButton?: boolean;
}

export const PageLayoutHeader = ({ title, subtitle = '', withBackButton = false, children }: PageLayoutHeaderProps) => {
  const navigate = useNavigate();

  return (
    <Stack direction="row" justifyContent="space-between">
      <div>
        <Stack direction="row" gap={1} alignItems="baseline">
          {withBackButton && (
            <IconButton onClick={() => navigate(-1)} style={{ maxHeight: 40 }}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography variant="h4" component="h1" gutterBottom>
            {title}
          </Typography>
        </Stack>
        {subtitle.length > 0 && (
          <Typography variant="subtitle1" gutterBottom>
            {subtitle}
          </Typography>
        )}
      </div>
      {children}
    </Stack>
  );
};
