import { useMutation } from 'react-query';
import { z } from 'zod';
import { useClient } from 'lib';

const responseSchema = z.object({
  setupIntentId: z.string(),
  clientSecret: z.string()
});
export type Data = z.infer<typeof responseSchema>;

export const usePaymentMethodMutation = () => {
  const client = useClient();

  return useMutation({
    mutationKey: 'users-id-payment-method-setup-intents',
    mutationFn: () =>
      client
        .post(`users/me/payment-method-setup-intents`)
        .then((res: any) => res.json())
        .then((res: any) => responseSchema.parse(res))
  });
};
