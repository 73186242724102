type Props = {
  amount: number;
};

const systemLocale = undefined; // when undefined Intl.NumberFormat takes locale from the system

const formatter = new Intl.NumberFormat(systemLocale, {
  style: 'currency',
  currency: 'USD'
});

// amount is in cents
export const Money = ({ amount }: Props) => {
  const formattedAmount = formatter.format(amount / 100);
  return <span>{formattedAmount}</span>;
};
