type Props = {
  date: Date;
};

export const FormattedDateWithTime = ({ date }: Props) => {
  return (
    <span>
      {date.toLocaleDateString()} {date.toLocaleTimeString()}
    </span>
  );
};
