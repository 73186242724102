export enum RoutesDefinition {
  shopDetails = '/shops/:id',
  shops = '/shops',
  shopsHolidays = '/shops/holidays',
  stripeTest = '/stripe-test',
  shopInvitations = '/shop-invitations/:token',
  users = '/users',
  userDetails = '/users/:id',
  wineProposals = '/wine-proposals',
  wineProposalDetails = '/wine-proposals/:id',
  wineProposalsV2 = '/v2/wine-proposals',
  wineProposalsV2Vintages = '/v2/wine-proposals/vintages',
  wineProposalDetailsV2 = '/v2/wine-proposals/:id',
  login = '/login',
  blindOrders = '/blind-orders',
  blindOrdersSettings = '/blind-orders-settings',
  blindOrderDetails = '/blind-orders/:id',
  wines = '/wines',
  winesV2 = '/wines-v2',
  wineDetails = '/wines/:id',
  wineDetailsV2 = '/wines-v2/:id',
  wineScanDetails = '/wine-scan/:id',
  createWine = '/wines/create',
  createWineV2 = '/wines-v2/create',
  editWine = '/wines/edit/:id',
  editWineV2 = '/wines-v2/edit/:id',
  winesUpload = '/wines/upload',
  winesUploadV2 = '/wines-v2/upload',
  imageLibrary = '/image-library',
  imageLibraryReview = '/image-library/review',
  imageLibraryAssignment = '/image-library/assignment',
  imageLibraryUpload = '/image-library/upload',
  notificationsSend = '/notification/send',
  wineScan = '/wine-scan',
  wineScanTestScan = '/wine-scan/test',
  wineScanTestScanV2 = '/wine-scan/test-v2',
  wineScanTestScanV3 = '/wine-scan/test-v3',
  mlImages = '/ml-images',
  mlImagesImport = '/ml-images/import',
  updateMlImagesImport = '/ml-images/bulk-update',
  mlImagesBulkDelete = '/ml-images/bulk-delete',
  mlImagesTransfer = '/ml-images/transfer',
  wineProducers = '/wine-producers',
  wineProducerDetails = '/wine-producers/:id',
  wineProducerImport = '/wine-producers/import',
  wineProducerCreation = '/wine-producers/create',
  wineProducerEdit = '/wine-producers/:id/edit',
  insights = '/insights',
  notFound = '/not-found',
  orders = '/orders',
  orderDetails = '/orders/:id'
}
