import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import { Loader } from 'common';
import { useSession } from 'features/auth';

const PublicRouting = React.lazy(() => import('routes/public'));
const ProtectedRouting = React.lazy(() => import('routes/protected'));

function App() {
  const { isLogged, isReady } = useSession();

  // TODO: add loading page (waiting for firebase auth loaded)
  if (!isReady) {
    return <Loader />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <React.Suspense fallback={<Loader />}>{isLogged ? <ProtectedRouting /> : <PublicRouting />}</React.Suspense>
    </LocalizationProvider>
  );
}

export default App;
