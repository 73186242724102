import { PaymentIntent, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState, useRef } from 'react';

const apiKey = String((window as any)?.SERVER_DATA?.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const usePaymentIntent = (
  clientSecret: string,
  stripeAccount: string
): [boolean, boolean, PaymentIntent | undefined, string, any] => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>();
  const stripeClientRef = useRef<any>(null);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    console.log('===============');
    console.log(stripeAccount);
    const stripeClient = loadStripe(apiKey, {
      stripeAccount
    });
    stripeClientRef.current = stripeClient;
    stripeClient
      .then((stripe) =>
        stripe
          ?.retrievePaymentIntent(clientSecret)
          .then((result: any) => {
            if (result.error) {
              setIsError(true);
              setError(String(result.error?.message));
            } else {
              setPaymentIntent(result.paymentIntent);
            }
          })
          .then(() => {
            setIsLoading(false);
          })
      )
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setError(err.message);
      });
  }, [stripeClientRef, clientSecret, stripeAccount, setIsError, setError, setIsLoading]);

  return [isLoading, isError, paymentIntent, error, stripeClientRef.current];
};
