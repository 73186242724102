import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
interface ConfirmDialogProps {
  open: boolean;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  title?: string;
  content?: string;
}

export const ConfirmDialog = ({ open, onConfirm, onCancel, title, content }: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Button variant="outlined" color="error" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
