import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorScreen = () => {
  return (
    <div>
      <h1>Some error appears</h1>
    </div>
  );
};

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return <Sentry.ErrorBoundary fallback={<ErrorScreen />}>{children}</Sentry.ErrorBoundary>;
};
