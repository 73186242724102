import BadgeIcon from '@mui/icons-material/Badge';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import CollectionsIcon from '@mui/icons-material/Collections';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import GroupIcon from '@mui/icons-material/Group';
import LuggageIcon from '@mui/icons-material/Luggage';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PendingActionIcon from '@mui/icons-material/PendingActions';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RedeemIcon from '@mui/icons-material/Redeem';
import StoreIcon from '@mui/icons-material/Store';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WineBarIcon from '@mui/icons-material/WineBar';
import { Box, List } from '@mui/material';
import React, { ReactElement } from 'react';
import { RoutesDefinition } from 'routes';
import { MainMenuItem } from './MainMenuItem';

const mainMenuLinks: { icon: ReactElement; primary: string; to: string }[] = [
  {
    icon: <GroupIcon />,
    primary: 'Users',
    to: RoutesDefinition.users
  },
  {
    icon: <StoreIcon />,
    primary: 'Shops',
    to: RoutesDefinition.shops
  },
  {
    icon: <LuggageIcon />,
    primary: 'Shops Holidays',
    to: RoutesDefinition.shopsHolidays
  },
  {
    icon: <RedeemIcon />,
    primary: 'Blind Orders',
    to: RoutesDefinition.blindOrders
  },
  {
    icon: <WineBarIcon />,
    primary: 'Wines',
    to: RoutesDefinition.wines
  },
  {
    icon: <WineBarIcon />,
    primary: 'Wines V2',
    to: RoutesDefinition.winesV2
  },
  {
    icon: <BadgeIcon />,
    primary: 'Wine producers',
    to: RoutesDefinition.wineProducers
  },
  {
    icon: <PendingActionIcon />,
    primary: 'Wine proposals',
    to: RoutesDefinition.wineProposals
  },
  {
    icon: <PendingActionIcon />,
    primary: 'Wine proposals V2',
    to: RoutesDefinition.wineProposalsV2
  },
  {
    icon: <UploadFileIcon />,
    primary: 'Upload Wines',
    to: RoutesDefinition.winesUpload
  },
  {
    icon: <CollectionsIcon />,
    primary: 'Image library',
    to: RoutesDefinition.imageLibrary
  },
  {
    icon: <CenterFocusWeakIcon />,
    primary: 'ML images',
    to: RoutesDefinition.mlImages
  },
  {
    icon: <NotificationsIcon />,
    primary: 'Notifications',
    to: RoutesDefinition.notificationsSend
  },
  {
    icon: <DocumentScannerIcon />,
    primary: 'Wine scan',
    to: RoutesDefinition.wineScan
  },
  {
    icon: <ManageSearchIcon />,
    primary: 'Insights',
    to: RoutesDefinition.insights
  },
  {
    icon: <ReceiptLongIcon />,
    primary: 'Stripe test panel',
    to: RoutesDefinition.stripeTest
  },
  {
    icon: <ReceiptIcon />,
    primary: 'Marketplace orders',
    to: RoutesDefinition.orders
  }
];

interface MainMenuProps {
  onClick: VoidFunction;
}

export const MainMenu = ({ onClick }: MainMenuProps) => {
  return (
    <Box sx={{ width: '250px' }}>
      <List>
        {mainMenuLinks.map((link) => (
          <MainMenuItem onClick={onClick} key={link.to} {...link} />
        ))}
      </List>
    </Box>
  );
};
