import { CircularProgress, styled } from '@mui/material';
import { memo } from 'react';

const LoaderWrapper = styled('div')(
  ({ theme }) => `
    padding-top: ${theme.spacing(6)};
    padding-bottom: ${theme.spacing(6)};
    display:flex;
    justify-content: center;
    align-items: center;
  `
);

export const PageLayoutLoader = memo(() => {
  return (
    <LoaderWrapper>
      <CircularProgress size={60} />
    </LoaderWrapper>
  );
});
