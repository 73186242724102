import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { z } from 'zod';
import App from 'App';
import { ErrorBoundary } from 'common';
import { SessionContextProvider } from 'features/auth';
import { customErrorMap, QueryClientProvider, ApiProvider } from 'lib';
import '@fontsource/open-sans';

import 'lib/sentry';

z.setErrorMap(customErrorMap);

const theme = createTheme({
  palette: {
    primary: {
      main: '#780084'
    }
  },
  typography: {
    fontFamily: ["'Open Sans'", "'sans-serif'"].join(',')
  }
});

ReactDOM.render(
  <StrictMode>
    <QueryClientProvider>
      <Router>
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="success">
          <SessionContextProvider>
            <ApiProvider>
              <ErrorBoundary>
                <ThemeProvider theme={theme}>
                  <>
                    <CssBaseline enableColorScheme />
                    <App />
                  </>
                </ThemeProvider>
              </ErrorBoundary>
            </ApiProvider>
          </SessionContextProvider>
        </SnackbarProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById('root')
);
