import Button from '@mui/material/Button';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { FC } from 'react';

interface Props {
  clientSecret: string;
}

const Confirmation: FC<Props> = (props: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(props.clientSecret, {
      return_url: window.location.href
    });
    if (result.error) {
      console.log(result.error.message);
    }
  };

  return (
    <Button onClick={handleSubmit} variant="contained">
      Confirm payment
    </Button>
  );
};
export default Confirmation;
