import { zodResolver } from '@hookform/resolvers/zod';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormInput } from 'common';
import { useSession } from 'features/auth';

export const loginFormSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(1)
    .nonempty()
    .transform((val) => val.trim())
});

export type LoginFormData = z.infer<typeof loginFormSchema>;

export const LoginForm = () => {
  const { loginWithEmailAndPassword } = useSession();

  const [isLoading, setIsLoading] = useState(false);
  const [isCredentialsError, setCredentialsError] = useState(false);

  const formMethods = useForm<LoginFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {
    formMethods.watch(() => setCredentialsError(false));
  }, [formMethods]);

  const handleSubmit = async ({ email, password }: LoginFormData): Promise<void> => {
    setIsLoading(true);
    try {
      await loginWithEmailAndPassword(email, password);
    } catch (err: any) {
      setCredentialsError(true);
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Stack spacing={2}>
          {isCredentialsError ? <Alert severity="error">Invalid email or password!</Alert> : ''}
          <FormInput name="email" placeholder="Email" />
          <FormInput name="password" placeholder="Password" type="password" />
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Login'}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
