import ky, { Options } from 'ky';
import { useMemo } from 'react';
import { API_PREFIX } from 'common';
import { useSession } from 'features/auth';

const apiClient = (token: string | null) => {
  const config: Options = {
    prefixUrl: API_PREFIX as unknown as string
  };

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`
    };
  }

  return ky.create(config);
};

export const useClientWithNoLogOut = () => {
  const { token } = useSession();

  const apiClientInstance: any = useMemo(() => {
    return apiClient(token);
  }, [token]);

  return apiClientInstance;
};
