import { styled } from '@mui/system';

export const Logo = styled('div')(() => ({
  width: '150px',
  height: '150px',
  background: `url(${process.env.PUBLIC_URL + '/grapes-logo.png'})`,
  backgroundSize: 'contain',
  margin: '0 auto'
}));
export default Logo;
