import { Typography, Button, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StripePaymentIntent } from './StripePaymentIntent';

const stripeAccountParamName = 'stripe_account';
const paymentIntentClientSecretParamName = 'payment_intent_client_secret';

export const StripePaymentIntentInput = () => {
  const [isClientSecretProvided, setClientSecretProvidedFlag] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [stripeAccount, setStripeAccount] = useState('');
  const clientSecretInputRef = useRef(null);
  const stripeAccountInputRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const stripeAccountFromParams = searchParams.get(stripeAccountParamName);
    const clientSecretFromParams = searchParams.get(paymentIntentClientSecretParamName);
    if (stripeAccountFromParams && clientSecretFromParams) {
      setClientSecretProvidedFlag(true);
      setStripeAccount(stripeAccountFromParams);
      setClientSecret(clientSecretFromParams);
    }
  }, [searchParams, setStripeAccount, setClientSecret, setClientSecretProvidedFlag]);

  const onClickPay = useCallback(() => {
    if (!clientSecretInputRef.current) {
      alert('WTF');
      return;
    }
    setClientSecretProvidedFlag(true);
    const stripeAccountFromInput = String((stripeAccountInputRef.current as any).value);
    setStripeAccount(stripeAccountFromInput);
    const clientSecretFromInput = String((clientSecretInputRef.current as any).value);
    setClientSecret(clientSecretFromInput);
    searchParams.set(paymentIntentClientSecretParamName, clientSecretFromInput);
    searchParams.set(stripeAccountParamName, stripeAccountFromInput);
    setSearchParams(searchParams);
  }, [
    stripeAccountInputRef,
    clientSecretInputRef,
    setClientSecretProvidedFlag,
    setClientSecret,
    setStripeAccount,
    setSearchParams,
    searchParams
  ]);

  const onClickChangeClientSecret = useCallback(() => {
    setClientSecretProvidedFlag(false);
    setClientSecret('');
    setStripeAccount('');
    searchParams.delete(paymentIntentClientSecretParamName);
    searchParams.delete(stripeAccountParamName);
    setSearchParams(searchParams);
  }, [setClientSecretProvidedFlag, setClientSecret, searchParams, setSearchParams]);

  if (isClientSecretProvided) {
    return (
      <Stack direction="column" spacing={2}>
        <div>
          Secret: {clientSecret}, Stripe account: {stripeAccount}
        </div>
        <StripePaymentIntent clientSecret={clientSecret} stripeAccount={stripeAccount} />
        <Button onClick={onClickChangeClientSecret}>Change client secret</Button>
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h6">Pay for order</Typography>
      <TextField id="outlined-basic" label="Stripe account" variant="outlined" inputRef={stripeAccountInputRef} />
      <TextField id="outlined-basic" label="Client secret" variant="outlined" inputRef={clientSecretInputRef} />
      <Button onClick={onClickPay}>Pay</Button>
    </Stack>
  );
};
