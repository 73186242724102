import { useCallback, useState } from 'react';

interface Position {
  x: number;
  y: number;
}

const clearTranslate = (transform: string) => transform.replaceAll(/translate\(.+\)/g, '');

const calculatePositionOffset = (image: DOMRect, border: DOMRect, cursor: Position) => {
  const scale = image.width / border.width;
  const scaledBorderX = border.x - (border.width * scale - border.width) / 2;
  const scaledBorderY = border.y - (border.height * scale - border.height) / 2;
  return {
    x: scaledBorderX - image.x + cursor.x,
    y: scaledBorderY - image.y + cursor.y
  };
};

const createMoveImage = (image: HTMLImageElement, position: Position) => (e: Event) => {
  if (!image || !(e instanceof MouseEvent)) return;
  e.preventDefault();
  const dx = e.x - position.x;
  const dy = e.y - position.y;

  image.style.transform = `${clearTranslate(image.style.transform)} translate(${dx}px, ${dy}px)`;
};

const createStopDragging = (moveImage: (e: Event) => void) => {
  const stopDragging = (e: Event) => {
    e.target?.removeEventListener('mousemove', moveImage);
    e.target?.removeEventListener('mouseup', stopDragging);
    e.target?.removeEventListener('mouseleave', stopDragging);
    e.target?.removeEventListener('mouseout', stopDragging);
  };
  return stopDragging;
};

export const useDraggableImage = () => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [size, setSize] = useState<DOMRect | null>(null);

  const setElement = useCallback(
    (node: HTMLImageElement) => {
      if (!image) {
        setImage(node);
        setSize(node.getBoundingClientRect());
      }
    },
    [image]
  );

  const isCurrentImage = (e: MouseEvent) => {
    if (!image) return false;
    const rect = image.getBoundingClientRect();
    return e.x >= rect.left && e.x <= rect.right && e.y >= rect.top && e.y <= rect.bottom;
  };

  const startDragging = (e: MouseEvent) => {
    if (!isCurrentImage(e) || !size) return;
    const moveImage = createMoveImage(
      image!,
      calculatePositionOffset(image!.getBoundingClientRect(), size, { x: e.x, y: e.y })
    );

    const stopDragging = createStopDragging(moveImage);
    e.target?.addEventListener('mousemove', moveImage);
    e.target?.addEventListener('mouseup', stopDragging);
    e.target?.addEventListener('mouseleave', stopDragging);
    e.target?.addEventListener('mouseout', stopDragging);
  };

  if (image) {
    image.addEventListener('mousedown', startDragging);
    image.addEventListener('dragstart', (e: MouseEvent) => e.preventDefault());
  }
  return [setElement];
};
