import { Alert, CircularProgress, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import Confirmation from './Confirmation';
import Form from './Form';
import { usePaymentIntent } from './usePaymentIntent';

type Props = {
  clientSecret: string;
  stripeAccount: string;
};

export const StripePaymentIntent = ({ clientSecret, stripeAccount }: Props) => {
  const [isLoading, isError, paymentIntent, error, stripeClient] = usePaymentIntent(clientSecret, stripeAccount);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Alert severity="error">There is error: {error}</Alert>;
  }

  if (!paymentIntent) {
    return <Alert severity="error">WTF</Alert>;
  }

  return (
    <div>
      <Typography variant="body1" component="div" gutterBottom>
        id: {paymentIntent.id}
      </Typography>
      <Typography variant="body1" component="div" gutterBottom sx={{ bm: 3 }}>
        status: {paymentIntent.status}
      </Typography>
      {paymentIntent.status === 'requires_payment_method' && (
        <Elements stripe={stripeClient} options={{ clientSecret }}>
          <Form />
        </Elements>
      )}
      {paymentIntent.status === 'requires_confirmation' && (
        <Elements stripe={stripeClient} options={{ clientSecret }}>
          <Confirmation clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
};
