import ky, { NormalizedOptions, Options } from 'ky';
import { useMemo, useContext, createContext, ReactNode } from 'react';
import { API_PREFIX } from 'common';
import { useSession } from 'features/auth';
import { useClient } from './apiClient';

const unauthorizedHandler = (
  request: Request,
  options: NormalizedOptions,
  response: Response,
  logout: VoidFunction
) => {
  if (response.status === 401) {
    logout();
  }
};

const apiClient = (token: string | null, logoutHandler: VoidFunction) => {
  const config: Options = {
    prefixUrl: API_PREFIX as string,
    hooks: {
      afterResponse: [(request, options, response) => unauthorizedHandler(request, options, response, logoutHandler)]
    }
  };

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`
    };
  }

  return ky.create(config);
};

interface ApiClient {
  get: (path: string, options: unknown) => Promise<{ ok: boolean; json: () => Promise<any> }>;
  post: (
    path: string,
    options: { json: unknown; headers: Record<string, string> }
  ) => Promise<{ ok: boolean; json: () => Promise<any> }>;
  patch: (
    path: string,
    options: { json: unknown; headers: Record<string, string> }
  ) => Promise<{ ok: boolean; json: () => Promise<any> }>;
}

export const ApiContext = createContext<ApiClient>({
  get: (path: string, optons: unknown) => Promise.reject('not implemented'),
  post: (path: string, options: { json: unknown; headers: Record<string, string> }) =>
    Promise.reject('not implemented'),
  patch: (path: string, options: { json: unknown; headers: Record<string, string> }) =>
    Promise.reject('not implemented')
});

export const useApi = (): ApiClient => {
  return useContext(ApiContext);
};

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { token, logout } = useSession();
  const client = useClient();

  const value = useMemo<ApiClient>(() => {
    return {
      get: (path: string, options: unknown): Promise<{ ok: boolean; json: () => Promise<any> }> => {
        return client.get(path, options as any);
      },
      post: (path: string, options: { json: unknown; headers: Record<string, string> }) => {
        return client.post(path, options);
      },
      patch: (path: string, options: { json: unknown; headers: Record<string, string> }) => {
        return client.patch(path, options);
      }
    };
  }, [client]);

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
