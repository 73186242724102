import { FormControlLabel, FormGroup } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useController } from 'react-hook-form';

interface InputProps {
  name: string;
  label: string;
  defaultValue?: boolean;
  disabled?: boolean;
}

export const SwitchInput = ({ name, defaultValue, disabled, label, ...rest }: InputProps): JSX.Element => {
  const {
    field: { ref, ...fieldProps }
  } = useController({
    name,
    defaultValue
  });

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch disabled={disabled} checked={fieldProps.value ?? defaultValue} ref={ref} {...rest} {...fieldProps} />
        }
        label={label}
      />
    </FormGroup>
  );
};
